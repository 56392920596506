import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 6em 0em 0em 0em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  padding: 0em 5em !important;
  text-align: justify !important;
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="The Importance Of A Mentor" keywords={[`host family english`, `hfe`]} />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <TextContainer>
        <p>
          We believe that mentoring students in real life situations is far more
          effective than a simple language course, as it builds confidence,
          motivation and above all, ensures the student actually learns the
          English they want to learn by living it. This is a real life
          experience and goes back to how we all learned our language skills –
          not at school, but in a caring family atmosphere.
        </p>
        <p>
          In our opinion, Homestay should not be listed as an ‘Accommodation’
          service as it provides much more than that when done properly. Many
          international students need assistance with basic conversational
          English and we saw the Host with additional mentoring training being
          in an ideal situation to help with this if it is properly managed and
          supervised. The ideal situation for a student is to do formal English
          lessons (or their academic studies) during the day with their
          Education Provider and then stay in an English speaking home where
          there is mentor support and structure for conversational English
          improvement.
        </p>
      </TextContainer>
    </Grid>
  </Layout>
)

export default IndexPage
